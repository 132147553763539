
import { Observable, of } from 'rxjs';
import { takeWhile, map } from 'rxjs/operators';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Inject} from '@angular/core';

import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';


import { UserService } from '../providers/user.service';
import { GameService } from '../providers/game.service';
import { Game } from '../models/game';
import { Week } from '../models/week';


@Component({
  selector: 'app-picks-page',
  templateUrl: './picks-page.component.html',
  styleUrls: ['./picks-page.component.css']
})
export class PicksPageComponent implements OnInit, OnDestroy {

  _usrsrv: UserService;
  private _gameservice: GameService;
  private _db: AngularFireDatabase;

  private continue_subs: boolean;

  _games: Observable<Game[]>;
  _byeTeams: String[];

  _weeksref: AngularFireList<Week>;
  _weeks: Observable<Week[]>;
  //_selectedWeek: string;

  _weekid: string;

  _numPicksMade: number = 0;
  _numGamesInWeek: number = 0;

  constructor(private usrsrv: UserService,
              private gamesrv: GameService,
              private route: ActivatedRoute,
              private router: Router,
              public snackBar: MatSnackBar,
              afdb: AngularFireDatabase,
              public dialog: MatDialog) { 

    this._usrsrv = usrsrv;
    this._gameservice = gamesrv;
    this._db = afdb;

    // First thing to do is decide what week, or did it come as a url param?
  }


  ngOnInit(): void {
    this.continue_subs = true;
    console.log("Picks/ngOnInit()");

    this._weekid = this.route.snapshot.params['weekid'];
    console.log("Got param: "+this._weekid);
    
/*    if( this.usrsrv.getActivePool() ) {
      this.loadPicksForWeek( this._weekid);
      this.loadWeeks();
    }
    else*/ {
      console.log( "subscribe to pool change?");
      this.usrsrv.navItem$.pipe(takeWhile( () => this.continue_subs)).subscribe( (value: boolean) => {
          console.log("got subscription");
          if( this.usrsrv.getActivePool() ) {
              console.log( "got active pool");
              this.loadPicksForWeek( this._weekid);
              this.loadWeeks();
          }
          else
            console.log('No Active pool?')
      });
    }
  }


  ngOnDestroy() {
    this.continue_subs = false;
  }


  loadWeeks(){

    let key = "/Pools/"+this.usrsrv.getActivePool().ID;

    this._weeksref = this._db.list<Week>(key+"/Weeks", ref=> ref.orderByValue().equalTo(true));
    this._weeks = this._weeksref.snapshotChanges().pipe(map( changes => {
      return changes.map( c=> ({ key: c.payload.key, ...c.payload.val()
      }));
    }));
    /*{
      query: {
        orderByValue: true,
        //orderByChild: 'Enabled',
        equalTo: true
      }
    });*/

/*    this._weeks.subscribe( weeks => {
      console.log("Selected wk: "+this._weekid);
      this._selectedWeek = this._weekid;
    })  */
  }


  loadPicksForWeek( weekid ){

    console.log('loadPicksForWeek');
    if( weekid == "default" ) {
      this._weekid = this._usrsrv.getActivePool().ActiveWeek;
      console.log(" Activating default week: " + this._weekid);
    }
    else {
      this._weekid = weekid;
    }

    let gamesForWeek: Game[];
    gamesForWeek = [];

    const dbkey = "/Weeks/"+this._weekid;

    // Load all the games for the week
    console.log('About to load games for: ', dbkey)
    this._db.list(dbkey).snapshotChanges().pipe( 
      takeWhile( () => this.continue_subs),
      map(actions => 
        actions.map(a => ({ key: a.key, ...a.payload.val() as {}}))
      )).subscribe( games => {
      this._numGamesInWeek = games.length;
      
      console.log('loading games...')
      games.forEach( game => {
        let newgame: Game = this._gameservice.parseGame( game, game.key );

        let updated: boolean = false;
        for( var gm of gamesForWeek ){
          if( newgame.gid == gm.gid ) {
            // Game exists - just update it
            console.log( "updating game: "+gm.gid );
            gm.status = newgame.status;
            gm.startDay = newgame.startDay;
            gm.homeScore = newgame.homeScore;
            gm.awayScore = newgame.awayScore;
            gm.allowSpreadUpdate = newgame.allowSpreadUpdate;
            gm.allowTimeUpdate = newgame.allowTimeUpdate;
            gm.situation = newgame.situation;
            updated = true;
            break;
          }
        }

        if( !updated ) {
          //console.log("Adding new game: "+newgame.gid);
          gamesForWeek.push( newgame );
        }
      })
      
      console.dir(gamesForWeek);

      gamesForWeek.sort((g1,g2) => {
        if( g1.start > g2.start ){
          return 1;
        }
        if( g1.start < g2.start ){
          return -1;
        }

        return 0;
      });

      this._games = of( gamesForWeek);

      //
      this._byeTeams = this._gameservice.getByeTeams( gamesForWeek );

      // Now I have the games for the week, grab (any) user picks
      this._db.list("/PoolPicks/"+this.usrsrv.getActivePool().ID+"/"+this._weekid+"/"+this._usrsrv.uid)
      .snapshotChanges().pipe(
        takeWhile( () => this.continue_subs ),
        map(actions => 
          actions.map(a => ({ key: a.key, val: a.payload.val() }))
        )).subscribe( picks => {

          this._numPicksMade = picks.length;

          // Loop thru picks
          picks.forEach(pick => {

            // Loop thur games to find the matching game <-> pick
            for( var gm of gamesForWeek ){
              if( gm.gid == pick.key ) {
                console.log("Found pick: ");
                console.dir(pick); 
                // need to split off the bus here likely...
                // gm.pick = pick['val'] as string;
                let vals = (pick['val'] as string).split(',') 
                gm.pick = vals[0];
                gm.bus = vals.length > 1 ? true : false;
                console.log(pick); 
                break;
              }
            }
          });
            // This don't matter yet, as I can't make picks.
            // Work on the pick making code, then come back to this.
      });

    });
  }


  getWeekFromDate() {

  }


  pick( gameid, pick, team) {
    console.log( gameid+" "+pick);

    let ref: AngularFireObject<any> =  this._db
      .object("/PoolPicks/"+this._usrsrv.getActivePool().ID
      +"/"+this._weekid+"/"
      +this._usrsrv.uid);

    ref.update({[gameid]:pick});

    this.snackBar.open("Picked: ",team,{
      duration:1000
    });

  }


  getClass( game, pick ) {

    if( game.status == "Final" ) {
      if( game.awayScore + game.getSpread( this._usrsrv.getActivePool().UseSpread, this._usrsrv.getActivePool().RoundSpread) > game.homeScore ) {
        if( pick == "away")
          return {'pick-right':true};
        if( pick == "home")
          return {'pick-wrong':true};
      }
      if( game.awayScore + game.getSpread(this._usrsrv.getActivePool().UseSpread, this._usrsrv.getActivePool().RoundSpread) < game.homeScore ) {
        if( pick == "home")
          return {'pick-right':true};
        if( pick == "away")
          return {'pick-wrong':true};
      }
    }

    // game in prgress
    if( game.status != "Not Started" ) {
      if( game.awayScore + game.getSpread(this._usrsrv.getActivePool().UseSpread, this._usrsrv.getActivePool().RoundSpread) > game.homeScore ) {
        if( pick == "away")
          return {'pick-maybe-right':true};
        if( pick == "home")
          return {'pick-maybe-wrong':true};
      }
      if( game.awayScore + game.getSpread(this._usrsrv.getActivePool().UseSpread, this._usrsrv.getActivePool().RoundSpread) < game.homeScore ) {
        if( pick == "home")
          return {'pick-maybe-right':true};
        if( pick == "away")
          return {'pick-maybe-wrong':true};
      }
    }

    return {};
  }

  selectWeek( event ){
    console.log("week changed");
    console.dir( event);

    this.loadPicksForWeek( event.value );
  }

  setActiveWeek(){
    console.log("Set active week");
    let ref = this._db.object("/Pools/"+this._usrsrv.getActivePool().ID);
    ref.update({ActiveWeek:this._weekid});
    this.snackBar.open("Active week set.","",{
      duration:2000
    });
  }


  editGame( game ) {

    console.log( game);
    let dialogRef = this.dialog.open(EditGameDialog,{
      data: game,
    });
    
    dialogRef.afterClosed().subscribe(game => {
      if( game != null ){
        console.log("Game edited.");
        console.dir( game );
        if( game.awayScore >0 || game.homeScore>0 ) {
         /* if( game.status == "Not Started") {
            game.status = "Ongoing";
          }*/
        }

        // reset the time, incase it was edited
        game.start = new Date( game.startString);
        game.startTime = game.start.toTimeString().split(' ')[0];

        //update the game[] with the new data (I think just store in the db) 
        let key = "/Weeks/"+this._weekid+"/"+game.gid;
        console.log("Key: "+key);
//        let ref: AngularFireObject<any[]> = 
        this._db.object(key).set({Status:game.status,
          HomeScore:game.homeScore,
          AwayScore:game.awayScore,
          Date:game.startString,
          Spread:game.spread,
          AllowAutoSpreadUpdate:game.allowSpreadUpdate,
          AllowAutoTimeUpdate:game.allowTimeUpdate
        });
      }
      else {
        console.log("Cancelled.");
      }
    });
  }
}


////////////////////

@Component({
  selector: 'edit-game-dialog',
  templateUrl: 'edit-game-dialog.html',
})
export class EditGameDialog {
  constructor(public dialogRef: MatDialogRef<EditGameDialog>,
              @Inject(MAT_DIALOG_DATA) public game: any) {}
}
