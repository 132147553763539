import {TrashTalk} from './trashtalk';

export class Pool {
    ID?: string;
    Name: string;
    UseSpread: boolean;
    RoundSpread: boolean;
    ActiveWeek?: string;
    Weeks?: any[];
    TrashTalk: TrashTalk;
    MoneyURL: string;
    Description?: string;
    Template?: string;


    constructor() {
        this.Name = null;
        this.UseSpread = null;
        this.RoundSpread = null;
        this.MoneyURL = null;
        this.Description = null;
        this.Template = null;
    }
}
