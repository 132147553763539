import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PoolService } from '../services/pool-service';
import { Pool } from '../models/pool';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {User} from '../models/user';


@Component({
  selector: 'app-edit-pool-page',
  templateUrl: './edit-pool-page.component.html',
  styleUrls: ['./edit-pool-page.component.css']
})
export class EditPoolPageComponent implements OnInit {

  _poolService : PoolService;
//  _pool: FirebaseObjectObservable<any>;
  _pool: Pool;
  _db: AngularFireDatabase;
  availableWeeks: AngularFireList<any[]>;
  availableUsers: AngularFireList<User>;
  _PoolId: string;
  _router: Router;
  UsersEnabled: boolean[];
  AvailableUsersArray: any;
  AvailableWeeksArray: any;
  AvailUsersObservabale: Observable<Array<any>>;
  arrayOkKeys;
  AvailWeeksObservabale: Observable<Array<any>>;
  arrayOfWeekKeys;
  numUsers;
  numSelectedUsers;

//  Name: string;
//  UseSpread: boolean;
//  RoundSpread: boolean;

  constructor(private route: ActivatedRoute, 
    private router: Router,
    private poolSrv: PoolService,
    public afDb: AngularFireDatabase) {

    this.AvailableUsersArray = [];
    this.AvailableWeeksArray = [];
    this._PoolId = this.route.snapshot.params['id'];
    let key = "/Pools/"+this._PoolId;

    this._poolService = poolSrv;
    this._router = router;
    this._db = afDb;

    // Deal with Users      
    this.availableUsers = afDb.list<User>('/Users', ref => ref.orderByChild('displayName'));
//    this.availableUsers
    this.availableUsers.snapshotChanges().pipe(
      map( actions =>
        actions.map( a => ({ key: a.key, ...a.payload.val() }))
      )
    )
    .subscribe( users => {

      // Store all available users in an array
      this.numUsers = 0;
      console.log("Users:");
      users.forEach(u => {
        console.log(u.key);
        this.numUsers++;
        this.AvailableUsersArray[u.key] = {key:u.key,Email:u.email,displayName:u.displayName};
      });

      // Now load the users that are in the pool and mark them as "checked" in the gui
      let UsersInPool = afDb.list( key+"/Users", ref => ref.orderByChild('Enabled').equalTo( true )
      /*{
        query: {
          orderByChild: 'Enabled',
          equalTo: true
        }
      }
    */      );

      UsersInPool.snapshotChanges().pipe( 
        map( actions => 
            actions.map( a => ({ key: a.key, ...a.payload.val() as {} }))
        )
      )
      .subscribe(usersinpool => {
        // match them up here and mark them checked
        this.numSelectedUsers = 0;
        usersinpool.forEach(u => {
          this.numSelectedUsers++;
          console.log("Enabled User:");
          console.dir( u );
          this.AvailableUsersArray[u.key].Enabled = 'true';
        });

        console.log("AvailUsers");
        this.arrayOkKeys = Object.keys(this.AvailableUsersArray);
        this.AvailUsersObservabale = of( this.arrayOkKeys);
        console.dir(this.arrayOkKeys);
      });
    });

    // Load the basic Pool
    this._pool = new Pool();

    let pool = this.afDb.object<Pool>(key).valueChanges().subscribe( pool => {
      this._pool.Name = pool.Name;
      this._pool.UseSpread = pool.UseSpread==null ? false : pool.UseSpread;
      this._pool.RoundSpread = pool.RoundSpread==null ? false : pool.RoundSpread;
      this._pool.MoneyURL = pool.MoneyURL==null ? "" : pool.MoneyURL;
      console.log( "Load: ");
      console.dir( pool);
    })

    // Now deal with the weeks
    this.availableWeeks = afDb.list('/Weeks');
    this.availableWeeks.snapshotChanges().pipe(
      map( actions =>
        actions.map( a => ({ key: a.key, ...a.payload.val() }))
      )
    )
    .subscribe( weeks => {

      // Store all available weeks in an array
      weeks.forEach(w => {
        this.AvailableWeeksArray[w.key] = {key:w.key};
      });

      // Now load the weeks that are in the pool and mark them as "checked" in the gui
      let WeeksInPool = afDb.list( key+'/Weeks', ref => ref.orderByValue().equalTo(true) );
      /*{
        query: {
          orderByValue: true,
          //orderByChild: 'Enabled',
          equalTo: true
        }
      } 
      ); */

      WeeksInPool.snapshotChanges().pipe( 
        map( actions => 
            actions.map( a => ({ key: a.key, ...a.payload.val() as {} }))
        )
      )
      .subscribe(weeksinpool => {
        // match them up here and mark them checked
        weeksinpool.forEach(w => {
          console.log("Enabled Week:");
          console.dir( w );
          this.AvailableWeeksArray[w.key].Enabled = 'true';
        });

        console.log("AvailWeeks");
        this.arrayOfWeekKeys = Object.keys(this.AvailableWeeksArray);
        this.AvailWeeksObservabale = of( this.arrayOfWeekKeys);
        console.dir(this.AvailableWeeksArray);
      });
    });

  }

  Save() {
    this._PoolId = this.route.snapshot.params['id'];
    let key = "/Pools/"+this._PoolId;
    console.log("Save key: " + key);
    let pool = this.afDb.object(key);

    console.log( "Save: ");
    console.dir( this._pool);
    console.log( "MoneyURL: "+this._pool.MoneyURL)
    pool.update({Name:this._pool.Name,
      UseSpread:this._pool.UseSpread,
      RoundSpread:this._pool.RoundSpread,
      MoneyURL:this._pool.MoneyURL});

    // currently, the pools deets are stored per user as well,
    // they will nedd to be found, and updated here as well...

    // Go back to the pools page
    this._router.navigate(['/pools']);
  }

  ngOnInit() {
  }

  OnUser( userId, event ) {
    // Got the userID and the poolID, write the record.

    console.log("User update - new status is: "+event.checked);
    console.dir(event);

    let key = "/Pools/"+this._PoolId+"/Users//";

    let ref = this._db.object( key );

    ref.update( {[userId]:{Enabled:event.checked}});

    // We also nee to add/remove this pool under the Main User record
    // seems like we may as well write the entire base pool record here
//    this._db.object("/Users/"+userId+"/Pools/"+this._PoolId).set( event.checked? "true": null);
    if( event.checked == true){
      console.log("Adding user to pool");
      this._db.object("/Users/"+userId+"/Pools/"+this._PoolId).set( {
        Name:this._pool.Name,
        ID:this._PoolId,
        RoundSpread:this._pool.RoundSpread,
        UseSpread:this._pool.UseSpread});
    } else {
      console.log("Removing user from pool");
      this._db.object("/Users/"+userId+"/Pools/"+this._PoolId).set( null);
    }
    
    console.log("Users (userid:current status): "+userId+" : "+this.AvailableUsersArray[userId].Enabled);
    console.dir( this.AvailableUsersArray);
    console.dir( this.AvailableUsersArray[userId]);
  }
  OnTest() {
    console.log("Test update - new status is: ");

  }


  OnWeek( weekId, event ) {
    // Got the userID and the poolID, write the record.
    console.log("Week update - new status is: "+event.checked);

    let key = "/Pools/"+this._PoolId+"/Weeks//";

    let ref = this._db.object( key );

    ref.update( {[weekId]:event.checked});
  }
}

