import { all } from "q";

export class Game {

    gid: string;
    start: Date;
    startString: String;
    startDay: string;
    startTime: string;
    status: string;

    home: string;
    away: string;
    awayfullname: string;
    homefullname: string;

    awaystyle: object;
    homestyle: object;

    homeScore: number;
    awayScore: number;

    allowSpreadUpdate: boolean;
    allowTimeUpdate: boolean;

    pick: string;
    bus: boolean;
    SpreadNote: string;

    situation: string;

    homerec: string;
    awayrec: string;
    homelocrec: string;
    awaylocrec: string;

    private spread: number;


    static getSpread(useSpread: boolean, useRounding: boolean, spread: number): number {
        // Rounding in this case always make the spread be a '.5'
        // to ensure no ties.
        if (useSpread) {
            if (useRounding) {
                if (spread == parseInt(spread.toString())) {
                    if (spread >= 0)
                        return spread + 0.5;
                    else
                        return spread - 0.5;
                }
                else
                    return spread;
            }
            else
                return spread;
        }
        else
            return 0;
    }


    constructor() { }

    setSpread(spread: number) { this.spread = spread; }

    getSpread(useSpread: boolean, useRounding: boolean) {
        return Game.getSpread(useSpread, useRounding, this.spread);
    }

    getSpreadNote() {
        return this.SpreadNote;
    }
    
    // IsLockedHTML() {
    //     let diff = this.start.getTime() - new Date().getTime();
    //     diff = diff / 1000; // convert from ms
    //     if (diff < 86400) {
    //         return '<br><i class="material-icons">lock</i>'
    //     }
    // }

    getGameAt(useSpread: boolean, useRounding: boolean) {
        if (!useSpread) {
            return 'at';
        }
        else {
            let diff = this.start.getTime() - new Date().getTime();
            console.log("Game Times")
            console.log( this.start )
            console.log( new Date())
            diff = diff / 1000; // convert from ms
            if (diff < 86400) { // spreads are locked within 1 day of game time...
                return 'at<br>(' + Game.getSpread(useSpread, useRounding, this.spread) + ')<br><i class="material-icons">lock</i>'
            }
            return 'at<br>(' + Game.getSpread(useSpread, useRounding, this.spread) + ')'

        }
    }
}
