
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { FirebaseApp } from '@angular/fire';
import { AngularFireDatabase  } from '@angular/fire/database';
import { Observable } from 'rxjs';


import { AuthService } from '../providers/auth.service';
import { UserService } from '../providers/user.service';
import { GameService } from '../providers/game.service';
import { Game } from '../models/game';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

    email: string;
    password: string;
    newemail: string;
    newpassword: string;
    private _db: AngularFireDatabase;
    accountCreated = false;
    error = '';

    constructor(public authService: AuthService, 
                public afDb: AngularFireDatabase, 
                public fbApp: FirebaseApp,
                public snackBar: MatSnackBar,
                private router:Router,
                public dialog: MatDialog) { 
        console.log('login-page ctor');

        this._db = afDb;
    }

    ngOnInit() {
    }

    login() {
        this.authService.loginWithGoogle().then((data) => {
            this.router.navigate(['']);
        })
    }

    loginFb() {
        this.authService.loginWithFacebook().then((data) => {
            this.router.navigate(['']);
        })
    }

    loginEmail() {

        console.log('Login with '+this.email+', '+this.password);

        this.authService.loginWithEmailandPassword(this.email,this.password).then((data) => {
            this.router.navigate(['']);
        }, error => {
            this.error = error.message;

        })
    }

    createAccount() {
        console.log('Create with '+this.newemail+', '+this.newpassword);
        this.error = '';

        this.authService.createAccount(this.newemail, this.newpassword).then((newuser) => {
            console.log('New user data:');
            console.dir( newuser );
            console.dir( newuser.user.uid );

            // Start the user
            const key = '/Users/'+newuser.user.uid;

            const ref = this._db.object(key);
            
            ref.set({displayName:(newuser.user.displayName == null || newuser.user.displayName==='' ? newuser.user.email : newuser.user.displayName),
                    Email:newuser.user.email,
                    providerId: 'password',
                    isAdmin: false,
                    isEnabled: false,
                    userInitialised: true
            });

            this.accountCreated = true;
            // this.router.navigate(['']);
        }, error => {
            this.error = error.message;

        });
    }

    forgotPassword( email) {
        const dialogRef = this.dialog.open(ForgotPasswordDialog,{data: email});
          
    //    dialogRef.componentInstance.dRef = dialogRef;

          dialogRef.afterClosed().subscribe(sendemail => {
            if( sendemail ){
              console.log('Send pwd reset link. send: '+ sendemail);

              this.fbApp.auth().sendPasswordResetEmail(sendemail)
              .then(response => {
                this.snackBar.open('Email sent!','',{
                    duration:3000
                  });
          },
                  err => {
                    console.log('err: ');
                    console.dir( err);
                    this.snackBar.open(err.message,'',{
                        duration:3000,
                        panelClass: ['md-warn']
                      });
                  });
            }
          });
    }
}


//////////////////// 

@Component({
    selector: 'forgot-password-dialog',
    templateUrl: 'forgot-password-dialog.html',
  })
  export class ForgotPasswordDialog {
    
    constructor(public dialogRef: MatDialogRef<ForgotPasswordDialog>,
        @Inject(MAT_DIALOG_DATA) public email: any ) {}
  }


  
@Component({
    selector: 'forgot-password-dialog',
    templateUrl: 'forgot-password-dialog.html',
  })
  export class ForgotPasswordDialog2 {

    constructor(public dialogRef: MatDialogRef<ForgotPasswordDialog>,
        @Inject(MAT_DIALOG_DATA) public email: string ) {}
  }
