import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Inject} from '@angular/core';

@Component({
  selector: 'app-reset-password-dialog',
  template: `
  <h3 md-dialog-title>Forgot Password?</h3>
  Please enter your email below and if it is in the database, we will send you a link to reset your password.
  <div md-dialog-content>
      <form #loginform="ngForm">
          <mat-form-field>
            <input mdInput [(ngModel)]="email" name="email" placeholder="Email" required email type="email">
          </mat-form-field>
        </form>
  </div>
  <div md-dialog-actions>
    <button mat-button [disabled]="!loginform.valid" (click)="dialogRef.close(email)">Send Email</button>
    <button mat-button (click)="dialogRef.close(null)">Cancel</button>
  </div>
  `,
  styles: []
})
export class ResetPasswordDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public email: string) { }

  ngOnInit() {
  }

}
