import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

import { Game } from '../models/game'

function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
  var jan = new Date(t.getFullYear(),0,1);
  var jul = new Date(t.getFullYear(),6,1);
  return Math.min(jan.getTimezoneOffset(),jul.getTimezoneOffset()) == t.getTimezoneOffset();  
}

@Injectable()
export class GameService {

  private _teammap:Map<string, Array<string>> = new Map(
      [ ["KC", ["Kansas City", "Chiefs", ]], 
        ["NE", ["New England", "Patriots"]],
        ["ARI", ["Arizona", "Cardinals"]],
        ["DET", ["Detroit", "Lions"]],
        ["ATL", ["Atlanta", "Falcons"]],
        ["CHI", ["Chicago", "Bears"]],
        ["BAL", ["Baltimore", "Ravens"]],
        ["CIN", ["Cincinnati", "Bengals"]],
        ["JAX", ["Jacksonville", "Jaguars"]],
        ["HOU", ["Houston", "Texans"]],
        ["NYJ", ["New York", "Jets"]],
        ["BUF", ["Buffalo", "Bills"]],
        // ["OAK", ["Oakland", "Raiders"]],
        ["TEN", ["Tennessee", "Titans"]],
        ["PHI", ["Philadelphia", "Eagles"]],
        // ["WAS", ["Washington", "Redskins"]],
        ["WSH", ["Washington", "Redskins"]],
        ["PIT", ["Pittsburgh", "Steelers"]],
        ["CLE", ["Cleveland", "Browns"]],
        ["TB", ["Tampa Bay", "Buccaneers"]],
        ["MIA", ["Miami", "Dolphins"]],
        ["IND", ["Indianapolis", "Colts"]],
        // ["LA", ["Los Angeles", "Rams"]],
        ["LAR", ["Los Angeles", "Rams"]],
        ["CAR", ["Carolina", "Panthers"]],
        ["SF", ["San Francisco", "49ers"]],
        ["SEA", ["Seattle", "Seahawks"]],
        ["GB", ["Green Bay", "Packers"]],
        ["NYG", ["New York", "Giants"]],
        ["DAL", ["Dallas", "Cowboys"]],
        ["NO", ["New Orleans", "Saints"]],
        ["LAC", ["Los Angeles", "Chargers"]],
        ["DEN", ["Denver", "Broncos"]],
        ["MIN", ["Minnesota", "Vikings"]],
        ["LV", ["Las Vegas", "Raiders"]]
      ]);

//      private _days: string[] = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      private _days: string[] = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
      
  private _db: AngularFireDatabase;

  constructor( afdb: AngularFireDatabase) {
    this._db = afdb;
   }


   // this fn not used?  It may now work since upgrade to Ang6...
  getGames( weekid, userid) {

    // Get games from weekid, create Game objs

    this._db.list("/Weeks/"+weekid).snapshotChanges().pipe( 
      map( actions => 
          actions.map( a => ({ key: a.key, ...a.payload.val() as {} }))
      )
    )
    .subscribe( games => {
      games.forEach( game => {
        // ###
        // games.push( this.parseGame( game, game.key ) );
      })
      
      //this._games = games;
    });


    // get any picks, update Game Objs
  }

  parseGame( dbgame, gameid ): Game {
    let g: Game = new Game();

    console.log("Parsing game: "+gameid);
    console.dir( dbgame);

    let temp: string = gameid;
    let tt: string[] = temp.split('_');

    g.gid = gameid;

    g.away = this._teammap.get(tt[1].toString())[1];
    g.home = this._teammap.get(tt[3].toString())[1];
    g.awayfullname = this._teammap.get(tt[1].toString())[0] + " " + this._teammap.get(tt[1].toString())[1];
    g.homefullname = this._teammap.get(tt[3].toString())[0] + " " + this._teammap.get(tt[3].toString())[1];

    let test = new Date();
    
    if( !isDST( test )) {
      // console.log("Daylight savings change.")
      // Change game.date from -4:00 to -5:00
      let tt: string = dbgame.Date
      tt = tt.replace('-04:', '-05:')
      dbgame.Date = tt
    }

    g.start = new Date(dbgame.Date);
    //console.log( g.start)
    g.startString = dbgame.Date;

    g.status = dbgame.Status;

    if( dbgame.AwayScore==null ) {
      g.awayScore = 0;
    }
    else {
      g.awayScore = dbgame.AwayScore;
    }

    if( dbgame.HomeScore==null ) {
      g.homeScore = 0;
    }
    else {
      g.homeScore = dbgame.HomeScore;
    }

    if( dbgame.Spread==null ) {
      g.setSpread(0);
    }
    else {
      g.setSpread(dbgame.Spread);
    }

    if( dbgame.AllowAutoSpreadUpdate == null ) {
      g.allowSpreadUpdate = true;
    }
    else {
      g.allowSpreadUpdate = dbgame.AllowAutoSpreadUpdate;
    }

    if( dbgame.SpreadNote == null ) {
      g.SpreadNote = "No spread yet.";
    }
    else {
      g.SpreadNote = dbgame.SpreadNote;
    }

    if( dbgame.AllowAutoTimeUpdate == null ) {
      g.allowTimeUpdate = true;
    }
    else {
      g.allowTimeUpdate = dbgame.AllowAutoTimeUpdate;
    }

    g.startDay = String(g.start.getMonth()+1) + "/"+String(g.start.getDate()) +", "+this._days[g.start.getDay()];
    g.startTime = g.start.toTimeString().split(' ')[0];
    
    g.startTime = g.startTime.substr(0, g.startTime.length-3);
    
    g.homestyle = {'background-image':'url(/assets/g/teams/'+g.home.toLowerCase()+'/helmet3/logo.png)',
         'background-size':'contain', 'background-position':'center',
          'background-repeat':'no-repeat',
          'display':'inline-block', 'width':'100px','height':'70px'};
    g.awaystyle = {'background-image':'url(/assets/g/teams/'+g.away.toLowerCase()+'/helmet3Away/logo.png)',
         'background-size':'contain', 'background-position':'center',
          'background-repeat':'no-repeat',
          'display':'inline-block', 'width':'100px','height':'70px'};

    if( dbgame.Situation == null ) {
      g.situation = "";
    }
    else {
      g.situation = dbgame.Situation;
    }

    g.awayrec = dbgame.AwayRec == null ? "" : dbgame.AwayRec;
    g.awaylocrec = dbgame.AwayLocRec == null ? "" : dbgame.AwayLocRec;
    g.homerec = dbgame.HomeRec == null ? "" : dbgame.HomeRec;
    g.homelocrec = dbgame.HomeLocRec == null ? "" : dbgame.HomeLocRec;
    
    console.dir(g);
    return g;
  }


  getByeTeams( games ) : String[] {

    let byeTeams: String[];
    byeTeams = [];

    console.log( 'getByeTeams' );

    // first build byeTeams by looping thru the team list map
    this._teammap.forEach((value, key, map)=>{
      //console.log(key);
      //console.log( value);
      byeTeams.push( value[1]);
    });

//    console.log( byeTeams);
    // Now loop thru the games and remove each team from the byeTeams array
    games.forEach( g => {
      let i = byeTeams.indexOf(g.away);
      if( i != -1 ) {
        byeTeams.splice(i, 1);
      }
      i = byeTeams.indexOf(g.home);
      if( i != -1 ) {
        byeTeams.splice(i, 1);
      }
//      console.log(g);
    });

    return byeTeams;
  }

}
