import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  usersRef: AngularFireList<any>;
  users: Observable<any[]>;
  _db: AngularFireDatabase;

  constructor(public afDb: AngularFireDatabase) { 
    this._db = afDb;
    this.usersRef = this._db.list('/Users', ref => ref.orderByChild('Email'));
    this.users = this.usersRef.snapshotChanges().pipe(map( changes => {
      return changes.map( c=> ({ key: c.payload.key, ...c.payload.val()
      }));
    }));

  }

  ngOnInit() {

  }

  enableUser( userid, event) {
    console.log("User update: "+userid+" - new enabled status is: "+event.checked);

    const key = "/Users/"+userid;
    const ref = this._db.object(key);
    ref.update({isEnabled:event.checked});
  }
}
