
import { Observable } from 'rxjs';
import {takeWhile,  map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../providers/auth.service';
import { UserService } from '../providers/user.service';
import { AngularFireDatabase, AngularFireList  } from '@angular/fire/database';
import {User} from '../models/user';



@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, OnDestroy {

    public _cols: any[];
    public _rows: any[];

    private continue_subs: boolean;

    constructor(private authService:AuthService, 
                private router:Router,
                public usrsrv: UserService,
                public afdb: AngularFireDatabase ) { 

    }

    ngOnDestroy() {
        this.continue_subs = false;
    }

    ngOnInit() {
        this.continue_subs = true;

        console.log( "subscribe to pool change?");
        this.usrsrv.navItem$.pipe(takeWhile( () => this.continue_subs)).subscribe( (value: boolean) => {
            console.log("got subscription");
            if( this.usrsrv.getActivePool() ) {
                console.log( "got active pool");
                this.loadScoreTable();
            } else {
                console.log('No active pool');
            }
        });
    }

    loadScoreTable() {
        this._cols = [{prop:'User'}];
        this._rows = [];

        // Now get the data to fill in the _rows[]
        let key = "/PoolScores/"+this.usrsrv.getActivePool().ID
        console.log('Active pool dbkey: ' + key );
        this.afdb.list(key).snapshotChanges().pipe( 
            takeWhile( () => this.continue_subs),
            map(actions => 
                actions.map(a => ({ key: a.key, ...a.payload.val() as {}}))
            )).subscribe( users => {
            users.forEach(user => {
                console.log( 'User score:');
                console.dir(user);
                let row = {};
                let index:number = 1;
                let userTotalScore: number = 0;

                // get the username
                //console.log("User:");
                //console.dir(user);
                row["User"] = user.key;
                row["uid"] = user.key;
                this.getUserInfo( user.key );
                                    
                // now iterate thru the weeks
                Object.keys(this.usrsrv.getActivePool().Weeks).map(week=>{
                    let score = user['weeks'][week];
                    if( !(score == null)) {
                        userTotalScore += +score;
                    } 
                    row[week]=score;
                });

                row['Tot'] = userTotalScore;

                // Is this an update?  Does the row already exist?
                let rindex = 0;
                for( let r of this._rows) {
                    if( r["uid"] == user.key ) {
                        this._rows[rindex] = row;
                        rindex = -1;
                        break;
                    }
                    rindex++;
                }

                // add new row
                if( rindex > -1)
                    this._rows.push( row);
                
            });

            // make the table update?
            this._rows = [...this._rows];
            this._cols = [...this._cols];
        });
        
        // get week names, and fill in the _cols[]
        Object.keys(this.usrsrv.getActivePool().Weeks )
            .map(key=>{
                console.log(key+":"+this.usrsrv.getActivePool().Weeks[key]);
                if( this.usrsrv.getActivePool().Weeks[key] == true ) {
                    // massage name here before adding
                    let wkname = key;
                    //let tt:string[] = wkname.split('-')
                    //console.log(tt);
                    wkname = "W"+(wkname.split('k')[1]);
                    this._cols.push({name:wkname,prop:key,width:50,cellClass:this.getCenterClass,headerClass:this.getCenterClass});
                }
            }
        );

        // One more col for total
        this._cols.push({name:'Tot',prop:'Tot',width:50,cellClass:this.getCenterClass,headerClass:this.getCenterClass});

        // make table update?
        this._cols = [...this._cols];

        //console.log("cols:");
        //console.dir (this._cols);


    }

    
    private getUserInfo( userid:string ) : any {
        let userinfo = {};
        let key: string = "/Users/"+userid;
        this.afdb.object<User>(key).valueChanges().pipe( 
          takeWhile(()=>this.continue_subs)).subscribe( user => {
//        this.afdb.object(key).map( user => {
          let email: string = user.email;
          let displayname: string  = user.displayName;
    
          // now search thru the rows for this userid and replace it with the name
          for( let r of this._rows ) {
            //console.log( r);
            if( r["User"] == userid ) {
              r["User"] = displayname;
              this._rows = [...this._rows];
              break;
            }
    
          }
    
          if( email == displayname) {
            userinfo["username"] = email;
            userinfo["tooltip"] = "";
          }
          else {
            userinfo["username"] = displayname;
            userinfo["tooltip"] = email;
            }
    
          return userinfo;
        });
      }


    logout() {
        this.authService.logout();
        this.router.navigate(['login']);
    }

    
  getCenterClass({ row, column, value }): any {
    return {'center-cell': true};
  }
 
}
