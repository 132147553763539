import { Injectable } from '@angular/core';
import { AngularFireDatabase  } from '@angular/fire/database';
import { Pool } from '../models/pool';


@Injectable()
export class PoolService {

    _db: AngularFireDatabase;

    constructor(public afDb: AngularFireDatabase){
        this._db = afDb;
    }

    getPool( id ) {
        console.log("Pool id: "+id);
        return this._db.object('/Pools/'+id);
    }
}
