
import { Observable } from 'rxjs';
import { takeWhile, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '../providers/user.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
// import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { User } from '../models/user';

import { Week } from '../models/week';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-match-up-page',
  templateUrl: './match-up-page.component.html',
  styleUrls: ['./match-up-page.component.scss']
})

export class MatchUpPageComponent implements OnInit, OnDestroy {

  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;
  @ViewChild('mytable') matchuptable;

  public _cols: any[];
  public _rows: any[];

  private _gameData = {};

  private continue_subs: boolean;

  _weeksref: AngularFireList<Week>;
  _weeks: Observable<Week[]>;
  _weekid: string;

  _items: Observable<any[]>;

  constructor(public usrsrv: UserService,
    public afdb: AngularFireDatabase) {
    console.log('Matchup ctor');
  }


  ngOnDestroy() {
    this.continue_subs = false;
  }


  ngOnInit() {
    console.log('Matchup OnInit');
    this.continue_subs = true;
    /*
    if( this.usrsrv.getActivePool() ) {
        this.loadMatchUp();
        console.log( "no active pool yet");
    }
    else */{
      console.log("subscribe to pool change?");
      this.usrsrv.navItem$.pipe(takeWhile(() => this.continue_subs)).subscribe((value: boolean) => {
        console.log("got subscription");
        if (this.usrsrv.getActivePool()) {
          console.log("got active pool");
          this.loadMatchUp(this.usrsrv.getActivePool().ActiveWeek);
          this.loadWeeks();
        }
      });
    }
  }


  selectWeek(event) {
    console.log('week changed');
    console.dir(event);

    this.loadMatchUp(event.value);
  }

  clickHeader(col: string): void {
    this.matchuptable.onColumnSort({ sorts: [{ prop: `${col["prop"]}`, dir: 'desc' }] });
  }

  loadMatchUp(weekid: string) {
    this._weekid = weekid;

    this._cols = [{ prop: 'User' }];
    this._rows = [];
    let g = {};
    this.continue_subs = true;

    // Load the games for the cols of the table
    const key: string = '/Weeks/' + weekid;
    this.afdb.list(key).snapshotChanges().pipe(
      takeWhile(() => this.continue_subs),
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() as {} }))
      )).subscribe(games => {
        games.forEach(game => {
          // this should iterate through the games?

          const g = this.getGameInfo(game);

          this._gameData[game.key] = g;

          // Have we been here before?
          // If so, just update rather than add a row
          let col_exists: boolean = false;
          let index = 0;
          for (const c of this._cols) {
            if (c['name'] === g['shortid']) {
              col_exists = true;
              this._cols[index] = { prop: g["shortid"], name: g["shortid"], width: 42, cellClass: this.getCellClass, headerTemplate: this.hdrTpl, game: g };
              break;
            }
            index++;
          }

          if (!col_exists) {
            this._cols.push({ prop: g['shortid'], name: g['shortid'], width: 42, cellClass: this.getCellClass, headerTemplate: this.hdrTpl, game: g });
          }
        });

        // add the Bus col if ur an admin
        if(this.usrsrv.isAdmin) {
          if (this._cols[this._cols.length - 3]["prop"] != "Bus")
          this._cols.push({ prop: "Bus", name: "Bus", width: 50, cellClass: this.getCenterClass, headerTemplate: this.hdrTpl });
        }

        // add one more column for total correct
        if (this._cols[this._cols.length - 2]["prop"] != "Tot")
          this._cols.push({ prop: "Tot", name: "Week", width: 50, cellClass: this.getCenterClass, headerTemplate: this.hdrTpl });

        // add one more column for total overall score
        if (this._cols[this._cols.length - 1]["prop"] != "OverallScore")
          this._cols.push({ prop: "OverallScore", name: "Overall", width: 50, cellClass: this.getCenterClass, headerTemplate: this.hdrTpl });

        console.log("columns");
        console.dir(this._cols);
        this._cols = [...this._cols];

        // Once, we have the game data, 
        // Now we can load the picks for the rows
        let pickkey: string = "/PoolPicks/" + this.usrsrv.getActivePool().ID + "/" + weekid;
        this.afdb.list(pickkey).snapshotChanges().pipe(
          takeWhile(() => this.continue_subs),
          map(actions =>
            actions.map(a => ({ key: a.key, val: a.payload.val() }))
          )).subscribe(userspicks => {
            userspicks.forEach(userpicks => {
              let row = {};
              let userinfo = this.getUserInfo(userpicks.key);
              //console.log( "userpicks");
              //console.dir( userpicks);
              let score = 0;
              let busScore = 0;
              let bus = false;
              Object.keys(userpicks.val).forEach(p => {
                //console.log(p);
                let game = this._gameData[p];
                let origpick = userpicks.val[p];
                if( origpick.split(",").length > 1 ){
                  busScore++;
                  bus = true;
                } 
                origpick = origpick.split(",")[0]
                let pick = "";
                // Only show the pick if the game is Started
                if (game["status"] != "Not Started") {
                  pick = (origpick == "home" ? game["shorthome"] : game["shortaway"]);;
                }

                // inc score if game is final and pick is right
                if (game["status"] == "Final") {
                  score += ((((game["ascore"] + game["spread"] > game["hscore"]) && (origpick == "away")) ||
                    ((game["ascore"] + game["spread"] < game["hscore"]) && (origpick == "home"))) ?
                    1 : 0);
                }

                // row[game["shortid"]] = pick + (bus ? ",bus" : "");
                row[game["shortid"]] = pick
                const busKey = game["shortid"]+"-bus"
                row[busKey] = bus
              });
              row["User"] = userpicks.key;
              row["uid"] = userpicks.key;
              row["Tot"] = score;
              row["Bus"] = busScore;

              this.getUserTotalScore(this.usrsrv.getActivePool().ID, userpicks.key);
              console.log(score);
              // make sure this row doesn't already exist
              let row_exists: boolean = false;
              let index = 0;
              for (let r of this._rows) {
                if (r["uid"] == userpicks.key) {
                  row_exists = true;
                  this._rows[index] = row;
                  break;
                }
                index++;
              }

              if (!row_exists) {
                this._rows.push(row);
              }
            });
          });
      });
  }


  private getGameInfo(game: any): any {
    let awayteam = game.key.split('_')[1];
    let hometeam = game.key.split('_')[3];
    let g = {};
    let spread: number = this.usrsrv.getPoolSpread(game.Spread);

    if (spread == null) {
      g["spread"] = 0;
      g["shortid"] = awayteam + " @ " + hometeam;
    }
    else {
      g["spread"] = spread;
      g["shortid"] = awayteam + " " + (spread >= 0 ? "+" : "") + spread + "@ " + hometeam;
    }
    g["shortaway"] = awayteam;
    g["shorthome"] = hometeam;
    g["status"] = game.Status;
    g["hscore"] = game.HomeScore;
    g["ascore"] = game.AwayScore;

    return g;
  }


  // could load this once and cache it?
  private getUserInfo(userid: string): any {
    let userinfo = {};
    let key: string = "/Users/" + userid;
    this.afdb.object<User>(key).valueChanges().pipe(
      takeWhile(() => this.continue_subs)).subscribe(user => {
        let email: string = user.email;
        let displayname: string = user.displayName;

        // now search thru the rows for this userid and replace it with the name
        for (let r of this._rows) {
          //console.log( r);
          if (r["User"] == userid) {
            r["User"] = displayname;
            this._rows = [...this._rows];
            break;
          }
        }

        if (email == displayname) {
          userinfo["username"] = email;
          userinfo["tooltip"] = "";
        }
        else {
          userinfo["username"] = displayname;
          userinfo["tooltip"] = email;
        }

        return userinfo;
      });
  }

  getCenterClass({ row, column, value }): any {
    return { 'center-cell': true };
  }


  getCellClass({ row, column, value }): any {
    // console.log(row)
    // console.log(column)

    const busKey = column["prop"] + "-bus"
    const bus = row[busKey]
    if (bus)
      console.log(value, bus)

    if (column.game["status"] != "Not Started") {
      if (column.game["status"] == "Final") {
        if (((column.game["ascore"] + column.game["spread"] > column.game["hscore"]) && (value == column.game["shortaway"])) ||
          ((column.game["ascore"] + column.game["spread"] < column.game["hscore"]) && (value == column.game["shorthome"]))
        ) {
          return { 'pick-right': true, 'bus': bus };
        }
        else {
          return { 'pick-wrong': true, 'bus': bus };
        }
      } else {
        if (((column.game["ascore"] + column.game["spread"] > column.game["hscore"]) && (value == column.game["shortaway"])) ||
          ((column.game["ascore"] + column.game["spread"] < column.game["hscore"]) && (value == column.game["shorthome"]))
        ) {
          return { 'pick-maybe-right': true, 'bus': bus };
        }
        else {
          return { 'pick-maybe-wrong': true, 'bus': bus };
        }
      }
    }
    else
      return {};
  }


  loadWeeks() {

    let key = "/Pools/" + this.usrsrv.getActivePool().ID;

    this._weeksref = this.afdb.list(key + "/Weeks", ref => ref.orderByValue().equalTo(true));
    this._weeks = this._weeksref.snapshotChanges().pipe(map(changes => {
      return changes.map(c => ({
        key: c.payload.key, ...c.payload.val()
      }));
    }));
    /*{
      query: {
        orderByValue: true,
        //orderByChild: 'Enabled',
        equalTo: true
      }
    });*/
  }

  getUserTotalScore(poolid: string, userid: string): void {
    // console.log("getUserTotalScore");
    // items: Observable<any[]>;
    // let key = "PoolScores";
    let key = "/PoolScores/" + poolid + "/" + userid + "/weeks";
    // console.log(key);
    // const ref = this.afdb.list(key);
    this.afdb.list(key).valueChanges().subscribe(data => {
      // console.log("Got some!");
      const total = data.reduce((a: number, b: number) => a + b, 0);
      // console.log(total);

      const index = this._rows.findIndex(r => r["uid"] === userid)
      this._rows[index]["OverallScore"] = total;
      // console.log( this._rows)
      return total;
    });

    // console.log(this._items);

    // console.log("END: getUserTotalScore");
    // return 10;
  }

}

