import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';

import { UserService } from '../providers/user.service';

@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.css']
})
export class UserProfilePageComponent implements OnInit {

  _displayname: string;

  constructor( public usrsrv: UserService,
              public afDb: AngularFireDatabase,
                public router: Router,
) {

    this._displayname = usrsrv.displayname;
  }

  ngOnInit() {
  }

  Save(){
    // Write changes back to db.
    this.afDb.object("/Users/"+this.usrsrv.uid).update({displayName:this._displayname});
    //this.usrsrv.displayname = this._displayname;
    this.router.navigate(['/']);
  }
}
