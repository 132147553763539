import { Component, OnInit } from '@angular/core';
import { ScoreUpdaterService } from '../providers/score-updater.service';


@Component({
  selector: 'app-score-updater',
  templateUrl: './score-updater.component.html',
  styleUrls: ['./score-updater.component.css']
})
export class ScoreUpdaterComponent implements OnInit {

  _json: string;

  constructor( scoreService: ScoreUpdaterService) { 
    scoreService.updateScores();
/*    scoreService.updateScores().subscribe( json => {
      this._json = json;
      console.log( "json:");
      console.dir(this._json);
    });*/
  }

  ngOnInit() {
  }

}
