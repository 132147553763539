import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';

import { Parser } from 'xml2js';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-seasons-page',
  templateUrl: './seasons-page.component.html',
  styleUrls: ['./seasons-page.component.css']
})
export class SeasonsPageComponent implements OnInit {

  seasons: AngularFireList<any[]>;
  weeks: AngularFireList<any[]>;
  games: AngularFireList<any[]>;
  _http: Http;
  _db: AngularFireDatabase;
  _year: string;


  constructor(public afDb: AngularFireDatabase, http: Http) {
    console.log("Hit Seasons ctor.");

    this._year = new Date().getFullYear().toString();
    this._http = http;
    this._db = afDb;
    this.seasons = afDb.list('/Weeks');
  }

  getLocalDate(oldDate) {
    //OffsetDateTime odt = OffsetDateTime.parse ( "2010-01-01T12:00:00+01:00" );

    let date: Date;
    //console.log(oldDate);
    date = new Date(oldDate);
    return date.toLocaleString();
  }

  OpenSeason(year) {
    this.weeks = this._db.list('/Seasons/' + year, ref => ref.orderByChild('createdAt'));
    /* {
       query: {
         orderByChild: 'createdAt'
       }
     } ); */
  }

  OpenWeek(year, week) {
    this.games = this._db.list('/Seasons/' + year + '/' + week, ref => ref.orderByChild('createdAt'));
    /*{
      query: {
        orderByChild: 'createdAt'
      }
    } ); */
  }

  parseGame(game, week, year): void {
    console.dir(game);
    let date: string = game.$.eid;
    let time: string = game.$.t;
    let ampm: string = game.$.q;
    let home: string = game.$.h;
    let away: string = game.$.v;
    date = date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2);

    time = time.replace(":", "");
    let temp: number = +time;

    if (ampm == "P") {
      temp += 1200
    }

    time = String(temp);

    while (time.length < 4) {
      time = "0" + time;
    }

    date += "T" + time.substr(0, 2) + ":" + time.substr(2, 2) + ":00-04:00";
    //console.log("Date: "+ date);

    let gameid: string = date + "_" + away + "_@_" + home;

    // Write record to db...
    let weeknum: string = "week" + (week < 10 ? "0" : "") + week;
    //    let season: AngularFireObject<any[]> = 

    this.afDb.object("/Weeks/" + this._year + "-" + weeknum + "/" + gameid).set({ Status: "Not Started", Date: date });
    //    season.set({Date:date, Home:home, Away:away});


  }

  import(): void {
    console.log("Hit Import.");
    var parser = new Parser();

    for (let wk = 1; wk <= 18; wk++) {
      https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?seasontype=2&week=1

      let req = "https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?seasontype=2&week=" + wk
      // let req = "http://www.nfl.com/ajax/scorestrip?season="+this._year+"&seasonType=REG&week="+wk
      this._http.request(req).subscribe((res) => {
        let json = JSON.parse(res.text());

        console.log("Week: ", wk);
        json.events.forEach(game => {
          this.parseESPNGame(game, wk, json.season.year);
        });
        // for (let game of result.ss.gms[0].g)
        //   this.parseGame(game, result.ss.gms[0].$.w, result.ss.gms[0].$.y);
      })
    }



    // var xml = this._http.request(req).subscribe((res) => {
    //   parser.parseString(res.text(), (err, result) => {
    //     for( let game of result.ss.gms[0].g )
    //       this.parseGame( game, result.ss.gms[0].$.w, result.ss.gms[0].$.y );
    //   })
    // });
  }

  parseESPNGame(game, week, year): void {

    // 2019-12-29T13:00:00-04:00_ATL_@_TB
    // need to generate a string like the above

    let date = new Date( game.date );
    let home = game.shortName.split('@')[1].trim();
    let away = game.shortName.split('@')[0].trim();

    date = new Date( Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0))
    date = convertTZ( date, "America/Toronto");
    let offset = date.getTimezoneOffset() + 90; // plus 90 to go to eastern from NL

    let dateString = date.toISOString().substr(0,16)+":00-0"+offset/60+":00";
    let gameid = dateString +"_"+away+"_@_"+home;

    // Write record to db...
    let weeknum: string = "week" + (week < 10 ? "0" : "") + week;

    console.log( dateString, gameid, weeknum )
    this.afDb.object("/Weeks/" + year + "-" + weeknum + "/" + gameid).set({ Status: "Not Started", Date: date });
    }


  popWeekIds(): void {
    console.log("Hit Populate.");

    for (let wk = 1; wk <= 18; wk++) {
      let key = '/Weeks/' + this._year + '-week' + (wk < 10 ? '0' : '') + wk;
      this._db.list(key, ref => ref.orderByChild('Date').limitToFirst(1))
        .snapshotChanges().pipe(
          map(actions =>
            actions.map(a => ({ key: a.key, ...a.payload.val() as {} }))
          ))
        .subscribe(games => {
          // ok got the first game of the week, build the new key
          console.log(games[0].key);
          let game = games[0].key;
          let dest = game.substring(0, 4);
          dest += game.substring(5, 7);
          dest += game.substring(8, 10) + '00';
          console.log(dest);

          // got the key and week, write it.
          let weekid = 'week' + (wk < 10 ? '0' : '') + wk;
          let ref: AngularFireObject<any> = this._db.object("/WeekIDByDate");
          console.log(weekid);
          ref.update({ [dest]: weekid });

        })
    }

  }

  ngOnInit() {
  }

}

function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}