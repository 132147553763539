import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


import {Pool} from '../models/pool';


@Component({
  selector: 'app-pools-page',
  templateUrl: './pools-page.component.html',
  styleUrls: ['./pools-page.component.css']
})
export class PoolsPageComponent implements OnInit {

  poolsref: AngularFireList<Pool>;
  pools: Observable<Pool[]> 
  router: Router;

  constructor(public afDb: AngularFireDatabase, r: Router) { 
    this.poolsref = afDb.list<Pool>('/Pools');
    //this.pools = this.poolsref.valueChanges();
    this.pools = this.poolsref.snapshotChanges().pipe(map(changes => {
      return changes.map(c =>({ key: c.payload.key, ...c.payload.val()
      }));
    }));
    this.router = r;
  }

  NewPool(): void {
    this.poolsref.push({Name:"NewPool",UseSpread:false,RoundSpread:false,MoneyURL:"",TrashTalk:{MaxMsgs:50,head:0}});
  }

  EditPool( poolID ): void {
    this.router.navigate(['/editpool',poolID]);
  }

  ngOnInit() {
  }

}
