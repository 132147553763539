import { Injectable } from '@angular/core';

// New imports to update based on @angular/fire version 4
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
// import firebase = require("firebase/app");

@Injectable()
export class AuthService {

  constructor(public afAuth: AngularFireAuth) { }

  loginWithGoogle() {
      return this.afAuth.signInWithPopup(
          new firebase.auth.GoogleAuthProvider() );
  }

  loginWithFacebook() {
      return this.afAuth.signInWithPopup(
          new firebase.auth.FacebookAuthProvider() );
  }

  loginWithEmailandPassword(e, p) {
      return this.afAuth.signInWithEmailAndPassword(e, p);
  }

  createAccount( e, p ) {
      return this.afAuth.createUserWithEmailAndPassword(e, p);
  }

  logout() {
      return this.afAuth.signOut();
  }

}
