
import {of as observableOf, Observable, BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';
import { Pool } from '../models/pool';

@Injectable()
export class UserService {

  displayname: string;
  email: string;
  isAdmin: boolean;
  uid: string;
  defaultPoolID: string;

  pools: Pool[];

  private activePool: Pool;

  _poolIsActive: boolean;
  _poolChange: Observable<boolean>;

  private _poolSource = new BehaviorSubject<boolean>(false);
  navItem$ = this._poolSource.asObservable();

  constructor() {
    this.pools = [];

    this._poolIsActive = false;
    console.log("Creating obsv: activepool is " + this._poolIsActive);
    this._poolChange = observableOf( this._poolIsActive );
  }


  setActivePool( p, key ){
    console.log( 'SetActivePool: ' + this._poolSource.getValue());
    console.dir(p);
    console.log('key '+key);
    this.activePool = p;
    this.activePool.ID = key;
    this._poolSource.next(!this._poolSource.getValue());
  }


  getActivePool() {
    return this.activePool;
  }


  public getPoolSpread(s:number): number {
    if( !this.activePool.UseSpread){
      return 0; }

    if( !this.activePool.RoundSpread) {
      return s; }

    // otherwise we are rounding spread to the closest .5, to avoid ties
    if( s > 0 ) {
      if( s == Math.trunc(s) ) {
        return s+0.5; }
      else {
        return s; }
    }
    else {
      if( s == Math.trunc(s) ) {
        return s-0.5; }
      else {
        return s; }
    }
  }
}
