
import { Observable } from 'rxjs';
import { takeWhile, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../providers/user.service';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { FirebaseApp } from '@angular/fire';

@Component({
  selector: 'app-trash-page',
  templateUrl: './trash-page.component.html',
  styleUrls: ['./trash-page.component.scss']
})
export class TrashPageComponent implements OnInit, OnDestroy {

//  _usrsrv: UserService;
  private _db: AngularFireDatabase;
  _msgsref: AngularFireList<any>;
  _msgs: Observable<any[]>;
//  _msgs: AngularFireList<any[]>;
  _trash: AngularFireObject<any>;
  _msg: string;
  private continue_subs: boolean;
  
  constructor(private usrsrv: UserService,
            afdb: AngularFireDatabase,
            private fbApp: FirebaseApp) { 
    
    this._db = afdb;
  //  this._usrsrv = usrsrv;
  }

  ngOnDestroy(){
    this.continue_subs = false;
  }

  ngOnInit() {
    this.continue_subs = true;
    /*
    if( this.usrsrv.getActivePool() ) {
        this.loadTrash();
        console.log( "no active pool yet");
    }
    else */{
        console.log( "subscribe to pool change?");
        this.usrsrv.navItem$.pipe(takeWhile( () => this.continue_subs)).subscribe( (value: boolean) => {
            console.log("got subscription");
            if( this.usrsrv.getActivePool() ) {
                console.log( "got active pool");
                this.loadTrash();
            }
        });
    }

  }


  loadTrash() {
      
    let key = "/Pools/"+this.usrsrv.getActivePool().ID+"/TrashTalk";
    this._trash = this._db.object(key);
    this._msgsref = this._db.list(key+"/Msgs", ref => ref.orderByChild('t'));
    this._msgs = this._msgsref.snapshotChanges().pipe(map( changes => {
      return changes.map( c=> ({ key: c.payload.key, ...c.payload.val()
      }));
    }));
    /*{
      query: {
        orderByChild: 't' // need to sort this on date
      }
    } ); */

    /*this._msgs.subscribe( msgs => {
      let ele = document.getElementById('msg_list');
      ele.scrollIntoView(false);
    })*/
  }

  ngAfterViewChecked() {        
    let ele = document.getElementById('msg_list');
    ele.scrollIntoView(false);
  } 

  sendMsg() {
    if( this._msg=="" ) {
      console.log("Empty msg");
      return;
    }

    
    //insert at head, and increment head in the transaction
    let key = "/Pools/"+this.usrsrv.getActivePool().ID+"/TrashTalk";
    let name = this.usrsrv.displayname;
    let msg = this._msg;

    console.log(key);
    
    const catRef = this.fbApp.database().ref(key);
    catRef.transaction( function( tt ) {
      console.log("In Trans.");
      
      // now can i update head, and post the new msg here?
      // first post new msg at head
      if( tt.Msgs == null) {
        // First time
        tt.Msgs = { [tt.head] : { m:msg,u:name,t:Date.now() }};
      }
      else /*if ( tt.Msgs[tt.head] == null)*/ {
        // overwriting/adding a msg
        tt.Msgs[tt.head] = { m:msg,u:name,t:Date.now() };
      }

      console.log( "tt:");
      console.dir(tt);

      // now inc head
      tt.head += 1;
      if( tt.head > tt.MaxMsgs ) {
        tt.head = 1;
      }

      return tt;
    }, function(error, committed, snapshot) {
      if (error) {
        console.log('Transaction failed abnormally!', error);
      } else if (!committed) {
        console.log('We aborted the transaction (because ada already exists).');
      } else {
/*        let ele = document.getElementById('msg_list');
        ele.scrollIntoView(false);*/
      }
    });

    this._msg = "";
  }
}
