import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
// import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
// import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';

import { AppComponent, defaultPool, genericPool1, pdPoolTemplate } from './app.component';
import { AuthService } from './providers/auth.service';
import { UserService } from './providers/user.service';
import { PoolService } from './services/pool-service';
import { GameService } from './providers/game.service';
//import { ScoreUpdaterService } from './providers/score-updater.service';

import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

// import { MaterialModule } from '@angular/material';

import { RouterModule, Routes } from '@angular/router';

import { PicksPageComponent, EditGameDialog } from './picks-page/picks-page.component';
import { LoginPageComponent, ForgotPasswordDialog2, ForgotPasswordDialog } from './login-page/login-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { MatchUpPageComponent } from './match-up-page/match-up-page.component';
import { PoolsPageComponent } from './pools-page/pools-page.component';
import { SeasonsPageComponent } from './seasons-page/seasons-page.component';
import { EditPoolPageComponent } from './edit-pool-page/edit-pool-page.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { OverlayModule } from '@angular/cdk/overlay';

import { PlatformModule } from '@angular/cdk/platform';
/*
import {MdAutocompleteModule,
        MdButtonModule,
        MdButtonToggleModule,
        MdCardModule,
        MdChipsModule,
        MdCheckboxModule,
        MdDialogModule,
        MdGridListModule,
        MdIconModule,
        MdInputModule,
        MdListModule,
        MdMenuModule,
        MdProgressBarModule,
        MdProgressSpinnerModule,
        MdRadioModule,
        MdRippleModule,
        MdSelectModule,
        MdSidenavModule,
        MdSliderModule,
        MdSlideToggleModule,
        MdSnackBarModule,
        MdTabsModule,
        MdToolbarModule,
        MdTooltipModule,
        PortalModule,
        StyleModule,
        A11yModule,
        CompatibilityModule
} from '@angular/material';  */
import { MatDialogModule } from '@angular/material/dialog'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'

// import 'hammerjs';
import { UsersComponent } from './users/users.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TrashPageComponent } from './trash-page/trash-page.component';
import { AgoPipePipe } from './utils/ago-pipe.pipe';
import { ScoreSheetPageComponent } from './score-sheet-page/score-sheet-page.component';
import { UserProfilePageComponent } from './user-profile-page/user-profile-page.component';
import { ScoreUpdaterComponent } from './score-updater/score-updater.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';

export const firebaseConfig = {
  apiKey: 'AIzaSyApQoAPoldZlosqLADxhCr0D-xf7pKpvuk',
  authDomain: 'footballpool-eb9d2.firebaseapp.com',
  databaseURL: 'https://footballpool-eb9d2.firebaseio.com',
  projectId: 'footballpool-eb9d2',
  storageBucket: 'footballpool-eb9d2.appspot.com',
  messagingSenderId: '1089681623399'
};

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'picks/:weekid', component: PicksPageComponent },
  { path: 'matchup', component: MatchUpPageComponent },
  { path: 'pools', component: PoolsPageComponent },
  { path: 'trashtalk', component: TrashPageComponent },
  { path: 'users', component: UsersComponent },
  { path: 'editpool/:id', component: EditPoolPageComponent },
  { path: 'seasons', component: SeasonsPageComponent },
  { path: 'admin', component: AdminPageComponent },
  { path: 'scores', component: ScoreUpdaterComponent },
  { path: 'profile', component: UserProfilePageComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    PicksPageComponent, EditGameDialog,
    LoginPageComponent, ForgotPasswordDialog, ForgotPasswordDialog2,
    HomePageComponent,
    AdminPageComponent,
    MatchUpPageComponent,
    PoolsPageComponent,
    SeasonsPageComponent,
    EditPoolPageComponent,
    UsersComponent,
    TrashPageComponent,
    AgoPipePipe,
    ScoreSheetPageComponent,
    UserProfilePageComponent,
    ScoreUpdaterComponent,
    ResetPasswordDialogComponent,
    defaultPool,
    genericPool1
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,

    AngularFireModule.initializeApp(firebaseConfig),
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    RouterModule.forRoot(routes),
  //  MaterialModule,
    FlexLayoutModule, /*
    MdAutocompleteModule,
    MdButtonModule,
    MdButtonToggleModule,
    MdCardModule,
    MdChipsModule,
    MdCheckboxModule,
    MdDialogModule,
    MdGridListModule,
    MdIconModule,
    MdInputModule,
    MdListModule,
    MdMenuModule,
    MdProgressBarModule,
    MdProgressSpinnerModule,
    MdRadioModule,
    MdRippleModule,
    MdSelectModule,
    MdSidenavModule,
    MdSliderModule,
    MdSlideToggleModule,
    MdSnackBarModule,
    MdTabsModule,
    MdToolbarModule,
    MdTooltipModule,
    CompatibilityModule,
    PortalModule,
    StyleModule,
    A11yModule, */
    OverlayModule,
    PlatformModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    // Material modules below
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule
   ],
  providers: [AuthService, PoolService, UserService, GameService, defaultPool, genericPool1, pdPoolTemplate ],
  bootstrap: [AppComponent],
  entryComponents: [ForgotPasswordDialog, EditGameDialog, ForgotPasswordDialog2, ResetPasswordDialogComponent ]
})
export class AppModule { }
