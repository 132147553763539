import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-score-sheet-page',
  templateUrl: './score-sheet-page.component.html',
  styleUrls: ['./score-sheet-page.component.css']
})
export class ScoreSheetPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
