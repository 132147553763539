
import { takeWhile, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http } from "@angular/http";
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { FirebaseApp } from '@angular/fire';
import { Game } from '../models/game';
// import "rxjs/add/operator/takeWhile";

@Injectable()
export class ScoreUpdaterService {
  private baseUrl = "http://www.nfl.com/liveupdate/scorestrip/ss.json";  // web api URL
  //private baseUrl = "https://crossorigin.me/http://www.nfl.com/liveupdate/scorestrip/ss.json";  // web api URL
  
  private games_sub: boolean;

  constructor(private http: Http,
    private afdb: AngularFireDatabase,
    private fbApp: FirebaseApp
  ) {
  }

  updateScores() { /*
    this.log("===================");
    this.log('Starting Updater...');

    this.games_sub = true;

    return this.getJSONScores().subscribe(json => {
      let week: number = Number(json["w"]);
      let weekkey: string = json["y"] + "-week" + (week < 10 ? "0" : "") + week;

      // Load all the games for the week
      let ref: AngularFireList<any[]> = this.afdb.list("/Weeks/" + weekkey);
      ref.takeWhile( () => this.games_sub). subscribe(games => {
        this.log("Started subscribe /Weeks/"+weekkey);
        // loop thru db games
        games.forEach(game => {
          // if this DB game is already "Final", then forget it and move on.
          this.log("Processing db game: "+game.$key+": "+ game.Status);
          if (game.Status != "Final") {

            // loop thru json games, until we find the match
            for (let g of json["gms"]) {
              // build the key and see if we are matching the db game
              let key: string = String(g["eid"]);
              key = key.substr(0, 4) + "-" + key.substr(4, 2) + "-" + key.substr(6, 2) + "T";

              // add the time
              let hours: string = String(g["t"]);
              let min: string = hours.split(":")[1];
              hours = hours.split(":")[0];

              let hours_num = Number(hours);
              if (hours_num < 12) {
                hours_num += 12;
              }
              hours = (hours_num < 10 ? "0" : "") + hours_num;

              key += hours + ":" + min + ":00-04:00_" + g["v"] + "_@_" + g["h"];

              if (key == game.$key) {
                // Matched game - now check for updates.
                this.log("Matched.");
                this.log(key);

                // Is the game started?
                if (g["q"] != "P") {
                  // Game has started.

                  // If this is the first time, then check the AutoPicker
                  if( game.Status == 'Not Started' ) {
                    // run AutoPicker
                    this.log("Running AutoPicker for week, game: "+weekkey+", "+game.$key);
                    this.updatePoolScoresForGame( game, weekkey, true );
                  }

                  // update score, if its changed
                  if( game.AwayScore != g["vs"] || game.HomeScore != g["hs"] ) {
                    ref.update(game.$key, { AwayScore: g["vs"], HomeScore: g["hs"] });
                    this.log('Updating score: '+g["vs"]+' at '+ g["hs"]);
                  }

                  if ((g["q"] == "F") || (g["q"] == "FO")) {
                    ref.update(game.$key, { Status: "Final" });
                    // Game marked final - call update pool scores.
                    this.log("Running ScoreUpdater for week, game: "+weekkey+", "+game.$key);
                    this.updatePoolScoresForGame( game, weekkey, false );
                  }
                  else {
                    ref.update(game.$key, { Status: "Q" + g["q"] });
                  }
                }
              }
            }
          }
          else
            this.log("Game: " + game.$key + " is already final.");
        })
        
        this.games_sub = false;
        this.log("Ended subscribe /Weeks/"+weekkey);
      });

      this.log("wkkey: " + weekkey);
      this.log("json wk: " + week);

    });
  */}


  // We have the game (with the data), now we loop thru all the pools,
  // checking to see if the particular pool has this week and its enabled.
  // grab all the picks for this game, and update the users' scores
  private updatePoolScoresForGame( game: any, weekid: string, autopicker: boolean) {
    /*let ref: AngularFireList<any[]> = this.afdb.list("/Pools/");
    ref.subscribe( pools => {
      this.log("Pools subscribe called. Auto: "+autopicker);
      pools.forEach( pool => {
        try {
          if( pool["Weeks"][weekid] == true) {
            // Pool does have this week, and its enabled.
            // Loop thru users in the pool
            let uusers = Object.keys(pool["Users"]);

            uusers.forEach(uid => {
              let user = pool["Users"][uid];
              if( user.Enabled == true ) {
                // Now we have enough info to do a specific search for this user's pick.
                let pickkey: string = "PoolPicks/"+pool.$key+"/"+weekid; //+"/"+uid; //+"/"+game.key;
                this.fbApp.database().ref(pickkey).child(uid+"/"+game.$key).once('value', pick => {

                  var pickObj = {};
                  if( autopicker && (pick.val() == null ) ) {
                    // pick doesn't exist.
                    // AutoPicker?
                    let pickkey2: string = "PoolPicks/"+pool.$key+"/"+weekid+"/"+uid;
                    let pickref: AngularFireList<any> = this.afdb.object(pickkey2);
                    let newPick: string = (Math.floor( Math.random() * 2 ) ? "home" : "away");
                    
                    this.log( "AutoPick: "+pickkey2+", "+newPick);
                    pickObj[game.$key] = newPick;
                    pickref.update(pickObj);
                    // Will this re-trigger this subscribe?  
                  }
                  else{
                    pickObj[game.$key] = pick.val();
                  }                  
                  // Check the pick and update the user's score 
                  // use the pool, game, and pick
                  if( !autopicker && this.gotPickRight(pool, game, pickObj[game.$key]) )
                  {
                    // Only thing left to do is increment the score in the db...
                    // need the poolid, userid, weekid
                    let updateScoreKey: string = "PoolScores/" +pool.$key+"/"+uid+"/weeks/"+weekid;
                    const catRef = this.fbApp.database().ref(updateScoreKey);
                    catRef.transaction( score => {
                      // now can i update head, and post the new msg here?
                      // first post new msg at head
                      if( score == null) {  
                        // First time
                        score = 1;
                      }
                      else  {
                        // inc score
                        score++;
                      }
                      
                      this.log( "game, weekid: "+ game.$key + ", "+weekid+" \nuid, score: "+ uid + ", "+score);
                      
                      return score;
                    }, function(error, committed, snapshot) {
                      if (error) {
                        this.log('Transaction failed abnormally!', error);
                      } else if (!committed) {
                        this.log('We aborted the transaction (because ??? exists?).');
                      } else {
                /*        let ele = document.getElementById('msg_list');
                        ele.scrollIntoView(false);*/ /*
                      }
                    }, false );
                  }
                });
              }
            });   
          }
        } catch (error) {
          
        }
      });
      this.log("Pools subscribe ended. Auto: "+autopicker);
    });
  */}


  private gotPickRight( pool, g, pick ) : boolean {
    
    let game = g;

    if( pool.UseSpread == true ){

      let spread: number = Game.getSpread(pool.UseSpread, pool.RoundSpread, game.Spread);

      if( ((game.AwayScore+spread) > game.HomeScore && pick == "away") ||
        (game.HomeScore > (game.AwayScore+spread) && pick == "home")
        )
        return true;
    }
    else {
      if( (game.AwayScore > game.HomeScore && pick == "away") ||
          (game.HomeScore > game.AwayScore && pick == "home")
      )
        return true;
    }

    return false; 
  }


  private log( s:string ){
    let today: Date = new Date();
    let key: string = 'dblog/'+today.toISOString().slice(0, 10);

    let ref: AngularFireObject<any> = this.afdb.object(key);
    var logObj = {};
    let k:string = today.toISOString().slice(11,23).replace('.','-');
    
    logObj[k] = s;
    ref.update(logObj);
    console.log(s);
  }


  private getJSONScores() {
    return this.http.get(this.baseUrl).pipe(map(res => res.json()));
//    return this.http.get(this.baseUrl).map(res => {res.json()},
//    err => {
//        console.log('Something went wrong!');
 //   });
    /*    return this.http.get(this.baseUrl)
        .map(res => <Customer[]> res.json())
        .catch(error => {
            console.log(error);
            return Observable.throw(error);
        }); */
  }
}
