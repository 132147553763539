import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';

import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from './providers/auth.service';
import { UserService } from './providers/user.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Message } from './models/message';
import { User } from './models/user';
import { Pool } from './models/pool';


@Injectable()
export class pdPoolTemplate {

    public _userPoolList: any;
    _poolSelect: string = ''

    isLoggedIn: Boolean;
    user_displayName: String;
    user_email: String;
    private _db: AngularFireDatabase;
    // private _templ: pdPoolTemplate;
    private PoolID: string;
    _poolName = '';
    moneyUrl = '';

    userref: AngularFireObject<User>;
    user: Observable<User>;


    constructor(public authService: AuthService,
        public afDb: AngularFireDatabase,
        public router: Router,
        public usrsrv: UserService,
        public snackBar: MatSnackBar) {

        this._db = afDb;

        //        this._templ = pdTempl;

        // console.log(router.url);

        this.authService.afAuth.authState.subscribe(
            (auth) => {
                if (auth == null) {
                    console.log(router.url);
                    console.log('Logged out');
                    this.isLoggedIn = false;
                    this.user_displayName = '';
                    this.user_email = '';
                    this._poolName = '';

                    // Here is our special case.
                    // In case our bot is trying to do the score updates
                    if (this.router.url === '/scores') {
                        this.router.navigate([this.router.url]);
                    } else {
                        this.router.navigate(['/login']);
                    }
                } else {
                    console.log('User logged in ' + auth.email);
                    // console.log(router.url);
                    this.isLoggedIn = true;
                    // this.user_displayName = auth.displayName;
                    this.user_email = auth.email;

                    usrsrv.email = auth.email;
                    usrsrv.uid = auth.uid;

                    const key = '/Users/' + auth.uid;
                    this.userref = afDb.object<User>(key);
                    this.user = afDb.object<User>(key).valueChanges();

                    // First login ever?
                    // const ref = this._db.object(key, { preserveSnapshot: true });
                    const ref = this._db.object<User>(key);
                    ref.snapshotChanges().subscribe(myuser => {
                        console.log('User obj: ');
                        console.dir(myuser.payload.val());

                        // First, check if user enabled.
                        if (myuser.payload.val().isEnabled == null ||
                            myuser.payload.val().isEnabled === false) {
                            // User not enabled - get outta here.
                            this.logout();
                        }

                        console.log('got here');
                        usrsrv.defaultPoolID = myuser.payload.val().defaultPool;
                        usrsrv.isAdmin = myuser.payload.val().isAdmin;
                        this.user_displayName = usrsrv.displayname = myuser.payload.val().displayName;
                        console.log('got here2');

                        if (myuser.payload.val().Pools != null) {
                            console.log('PoolID: ' + myuser.payload.val().Pools);
                            console.dir(myuser.payload.val().Pools);
                            this._userPoolList = [];
                            for (const pool in myuser.payload.val().Pools) {
                                this.PoolID = pool;
                                console.log(this.PoolID);
                                // Load each pool here.
                                usrsrv.pools.push(myuser.payload.val().Pools[pool]);
                                console.dir(usrsrv.pools);
                                console.log('Pool: ' + pool + ', ' + myuser.payload.val().Pools[pool].Name);
                                this._userPoolList.push({ id: pool, name: myuser.payload.val().Pools[pool].Name })
                                // usrsrv.activePool = myuser.val().Pools[pool];
                                console.dir(this._userPoolList)
                            };

                            // load pool names
                            this.loadPoolNames(this._userPoolList);

                            // Load the first pool here for now...
                            // prob need a fn for loadPool()
                            console.log('setting user active pool')
                            if (usrsrv.defaultPoolID != null) {
                                this.loadUserPool(usrsrv.defaultPoolID);
                            } else if (usrsrv.pools.length > 0) {
                                this.loadUserPool(usrsrv.pools[0].ID);
                            }
                        }
                    });

                    console.log('Logged in');
                    console.log(auth.uid);
                    console.dir(auth);
                    console.log(this.router.url);
                    // If user just 'actually' logged in, then redirect to home page
                    if (this.router.url == '/login') {
                        this.router.navigate(['']);
                    } else {
                        this.router.navigate([this.router.url]);
                    }
                }
            }
        );
    }

    ngInit() {

    }
    logout() {
        this.authService.logout();
        this.router.navigate(['login']);
    }

    selectPool(event) {
        console.log('pool changed');
        console.log(event.value);
        console.dir(event);

        this.loadUserPool(event.value);

        // var key = '/Users/'+this.usrsrv.uid;
        // this.user is my db ref I can use to update the def pool
        this.userref.update({ defaultPool: event.value });
    }


    loadPoolNames(userPoolList) {
        if (userPoolList.length > 0) {
            // console.log('Pools in list: ', userPoolList.length);
            let key = '/Pools';

            this.afDb.list<Pool>(key).snapshotChanges().pipe(
                map(actions =>
                    actions.map(a => ({ key: a.key, ...a.payload.val() }))
                )
            ).subscribe(pools => {
                pools.forEach(pool => {
                    userPoolList.forEach(upool => {
                        // console.log('upoolid: ' + upool.id);
                        // console.dir(pool);
                        if (upool.id == pool.key) {
                            // console.log('Found pool.');
                            upool.name = pool.Name;
                        }
                    });
                });
            });
                            /*            this.afDb.list( key ).valueChanges(). subscribe( pools => {
                                pools.forEach( pool => {
                                    userPoolList.forEach(upool => {
                                        console.log( "upoolid: "+ upool.id);
                                        console.dir( pool );
                                        if( upool.id == pool.$key ) {
                                            console.log("Found pool.");
                                            upool.name = pool.Name;
                                        }
                                    });
                                });
                            });
                        */      }
    }


    loadUserPool(key) {
        this.afDb.object<Pool>('/Pools/' + key).valueChanges()
            //       this._db.object("/Pools/"+key).snapshotChanges().pipe( map( action => {
            //           const key = action.payload.key;
            //           const data = { key, ...action.payload.val() };
            //           return data;
            //       }))
            .subscribe(thePool => {
                console.log('Pool:');
                console.dir(thePool);
                this._poolSelect = key;
                // console.dir(pp.val());
                // usrsrv.activePool = pp;
                console.log('setting pool active')
                this.usrsrv.setActivePool(thePool, key)

                // this._poolName = " - "+this.usrsrv.getActivePool().Name;
                this._poolName = ' - Pool: '
                this.moneyUrl = thePool.MoneyURL;

                // set up trash trigger
                const trashKey = '/Pools/' + this.usrsrv.getActivePool().ID + '/TrashTalk';

                this._db.list<Message>(trashKey + '/Msgs', ref => ref.orderByChild('t').limitToLast(1))
                    .valueChanges().subscribe(msgs => {

                        // only display if ur not on the trash page
                        if (!this.router.url.endsWith('trashtalk') && msgs.length) {
                            this.snackBar.open(msgs[0].u + ' says:', msgs[0].m, {
                                duration: 3000,
                            })
                        }
                    });

            })
    }

}

// ProDrinker
@Component({
    selector: 'pd-pool',
    templateUrl: './poolTemplates/prodrinker.html'
})
export class defaultPool extends pdPoolTemplate {
    constructor(public authService: AuthService,
        public afDb: AngularFireDatabase,
        public router: Router,
        public usrsrv: UserService,
        public snackBar: MatSnackBar) {
        super(authService,
            afDb,
            router,
            usrsrv,
            snackBar);
    }
}

// Bigs
@Component({
    selector: 'bigs-pool',
    templateUrl: './poolTemplates/bigs.html'
})
export class genericPool1 extends pdPoolTemplate {
    constructor(public authService: AuthService,
        public afDb: AngularFireDatabase,
        public router: Router,
        public usrsrv: UserService,
        public snackBar: MatSnackBar) {
        super(authService,
            afDb,
            router,
            usrsrv,
            snackBar);

    }
}



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    //    poolTemplate: 'Bigs'; // BIGS
    poolTemplate = 'PD'; // prodrinker
}
